import { lazy, useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

/*Layouts**/
const FullLayout = lazy(() => import("./layouts/FullLayout"));
const FullLayoutUser = lazy(() => import("./layouts/FullLayoutUser"));
const Shops = lazy(() => import("./views/ui/Shops"));
const Dashboard = lazy(() => import("./views/ui/Dashboard"));

const FullLayoutPlans = lazy(() => import("./layouts/FullLayoutPlans"));
const PlanDashboard = lazy(() => import("./views/ui/PlanDashboard"));
const Plans = lazy(() => import("./views/ui/Plans"));
const Users = lazy(() => import("./views/ui/Users"));
const UserView = lazy(() => import("./views/ui/UserView"));

const Orders = lazy(() => import("./views/ui/Orders"));
const Login = lazy(() => import("./views/ui/Login"));
const Privacy = lazy(() => import("./views/ui/Privacy"));
const Terms = lazy(() => import("./views/ui/Terms"));
const OrderList = lazy(() => import("./views/ui/OrderList"));
const UserList = lazy(() => import("./views/ui/UserList"));
const UserQRList = lazy(() => import("./views/ui/UserQrList"));
const ShopList = lazy(() => import("./views/ui/ShopList"));
const SalesTeamList = lazy(() => import("./views/ui/SalesTeamList"));
const ChooseProfile = lazy(() => import("./views/ui/ChooseProfile"));
const FullLayoutSales = lazy(() => import('./layouts/FullLayoutSales'));
const SalesShopList = lazy(() => import('./views/ui/SalesShopList'));
const ShopListAdmin = lazy(() => import('./views/ui/ShopListAdmin'));
const SalesDashboard = lazy(() => import('./views/ui/SalesDashboard'));
const ShopListRenewAdmin = lazy(() => import('./views/ui/ShopListRenewAdmin'));
const Messages = lazy(() => import('./views/ui/Messages'))

/** Pages ***/

const Userform = lazy(() => import("./views/ui/Userform"));

const App = () => {
  let [user, setUser] = useState(0);
  useEffect(() => {
    let user1 = localStorage.getItem('type');
    !user1 ? setUser(0) : user1 == 1 ? setUser(1) : user1 == 2 ? setUser(2) : setUser(3);
  }, [])
  return (<>
    <Routes>
      {user === 0 &&
        <><Route path="/" element={<FullLayoutUser />}>
          <Route path="/" element={<Login auth={(type) => { setUser(type); }} />} />
          <Route path="/login" element={<Login auth={(type) => { setUser(type); }} />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/user/:shopid" element={<Userform />} />
          <Route path="*" element={<Navigate to='/' />} />
          {/* <Route path="/orderlist" element={<OrderList></OrderList>} />
          <Route path="/shoplist" element={<ShopList></ShopList>} />

          <Route path="/chooseprofile" element={<ChooseProfile></ChooseProfile>} /> */}
        </Route>
        </>}
      {user === 1 &&
        <><Route path="/gems" element={<FullLayout logoutuser={() => setUser(0)} />}>
        <Route path="/gems/sales" element={<SalesTeamList logoutuser={() => setUser(0)} />} />
          <Route path="/gems" element={<Dashboard logoutuser={() => setUser(0)} />} />
        <Route path="/gems/list/:type" element={<ShopListAdmin logoutuser={() => setUser(0)} />} />
        <Route path="/gems/listrenew" element={<ShopListRenewAdmin logoutuser={() => setUser(0)} />} />
        <Route path="/gems/orders" element={<OrderList logoutuser={() => setUser(0)} ></OrderList>} />
        <Route path="/gems/users" element={<UserList logoutuser={() => setUser(0)} ></UserList>} />
        <Route path="/gems/usersqr" element={<UserQRList logoutuser={() => setUser(0)} ></UserQRList>} />
        </Route>
          <Route path="*" element={<Navigate to='gems' />} /></>}
      {user === 2 &&
        <><Route path="/plan" element={<FullLayoutPlans logoutuser={() => setUser(0)} />}>
        <Route path="/plan" element={<PlanDashboard logoutuser={() => setUser(0)} />} />
        <Route path="/plan/plans" element={<Plans logoutuser={() => setUser(0)} />} />
        <Route path="/plan/users" element={<Users logoutuser={() => setUser(0)} />} />
        <Route path="/plan/userview" element={<UserView logoutuser={() => setUser(0)} />} />
        <Route path="/plan/messages" element={<Messages logoutuser={() => setUser(0)} />} />
        </Route>
          <Route path="*" element={<Navigate to='plan' />} /></>}
      {user === 3 &&
        <><Route path="/sales" element={<FullLayoutSales logoutuser={() => setUser(0)} />}>
        <Route path="/sales" element={<SalesDashboard logoutuser={() => setUser(0)} />} />
        <Route path="/sales/shoplist/:type" element={<SalesShopList logoutuser={() => setUser(0)} />} />
        </Route>
          <Route path="*" element={<Navigate to='sales' />} /></>}
    </Routes></>
  )
};

export default App;